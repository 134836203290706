import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import {
  faHeadphonesSimple,
  faPrint,
  faGlobe,
  faLaptopCode,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";

export const PropsItemsDiagram = {
  digitacion: {
    icon: <FontAwesomeIcon icon={faKeyboard} />,
    text: "Digitación",
    classNameIcon: "icono-digitacion",
    classNameText: "texto-digitacion",
    classNameBodyArrow: "cuerpo-flecha-digitacion",
    classNameHeaderArrow: "cabeza-flecha-izquierda-digitacion",
    href: "#servicios",
  },
  digitalizacion: {
    icon: <FontAwesomeIcon icon={faPrint} />,
    text: "Digitalización (escaneo)",
    classNameIcon: "icono-escaneos",
    classNameText: "texto-escaneos",
    classNameBodyArrow: "cuerpo-flecha-escaneos",
    classNameHeaderArrow: "cabeza-flecha-derecha-escaneos",
    href: "#servicios",
  },
  transcripcion: {
    icon: <FontAwesomeIcon icon={faHeadphonesSimple} />,
    text: "Transcripción Audio a Texto",
    classNameIcon: "icono-transcripciones",
    classNameText: "texto-transcripciones",
    classNameBodyArrow: "cuerpo-flecha-transcripciones",
    classNameHeaderArrow: "cabeza-flecha-izquierda-transcripciones",
    href: "#servicios",
  },
  desarrolloWeb: {
    icon: <FontAwesomeIcon icon={faGlobe} />,
    text: "Desarrollo Web",
    classNameIcon: "icono-desarrollo-web",
    classNameText: "texto-desarrollo-web",
    classNameBodyArrow: "cuerpo-flecha-desarrollo-web",
    classNameHeaderArrow: "cabeza-flecha-derecha-desarrollo-web",
    href: "#servicios",
  },
  desarrolloSoftware: {
    icon: <FontAwesomeIcon icon={faLaptopCode} />,
    text: "Desarrollo de Software",
    classNameIcon: "icono-desarrollo-software",
    classNameText: "texto-desarrollo-software",
    classNameBodyArrow: "cuerpo-flecha-desarrollo-software",
    classNameHeaderArrow: "cabeza-flecha-izquierda-desarrollo-software",
    href: "#servicios",
  },
  ingresoDatosWeb: {
    icon: <FontAwesomeIcon icon={faCloudArrowUp} />,
    text: "Ingreso de Datos en la Web",
    classNameIcon: "icono-asesoria-asistencia",
    classNameText: "texto-asesoria-asistencia",
    classNameBodyArrow: "cuerpo-flecha-asesoria-asistencia",
    classNameHeaderArrow: "cabeza-flecha-derecha-asesoria-asistencia",
    href: "#servicios",
  },
};
