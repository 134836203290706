export const ItemDiagramMain = ({ props }) => {
  return (
    <>
      <div className={props.classNameIcon}>
        <a href={props.href}>
          <div>{props.icon}</div>
        </a>
      </div>
      <div className={props.classNameText}>
        <a href={props.href}>
          <div>{props.text}</div>
        </a>
      </div>
      <div className={props.classNameBodyArrow}></div>
      <div className={props.classNameHeaderArrow}></div>
    </>
  );
};
