import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { Anchor } from "../common/";
import { PropsAnchors } from "./types/PropsAnchors";
import "./rightsSocialFooter.css";

export const RightsSocialFooter = () => {
  const { texts } = useContext(LanguageContext);
  PropsAnchors.propsCopyrightText.text = texts.footerMainCopyrightText;
  return (
    <section className="copyright-redes-sociales">
      <div className="right-part">
        <p className="copy-right">{PropsAnchors.propsCopyrightText.text}</p>
        <Anchor
          className="enlace-dep"
          textHtml={PropsAnchors.propsEnlaceDep.text}
          propsAnchor={PropsAnchors.propsEnlaceDep}
        />
      </div>
      <div className="footer-header-social">
        <Anchor
          className="footer-icon-social footer-whatsapp"
          propsAnchor={PropsAnchors.propsWhatsapp}
        />
        <Anchor
          className="footer-icon-social footer-x"
          propsAnchor={PropsAnchors.propsX}
        />
        <Anchor
          className="footer-icon-social footer-facebook"
          propsAnchor={PropsAnchors.propsFacebook}
        />
        <Anchor
          className="footer-icon-social footer-linkedin"
          propsAnchor={PropsAnchors.propsLinkedin}
        />
        <Anchor propsAnchor={PropsAnchors.propsAriba} />
      </div>
    </section>
  );
};
