import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faSquareWhatsapp,
  faSquareXTwitter,
  faSquareFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// RIGHT side

export const PropsAnchors = {
  propsPhoneIcon: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faWhatsapp} beat />,
    title: "Móvil",
  },
  propsPhoneText: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: "(+51) 999 064 169",
    title: "Móvil",
  },
  propsEmailIcon: {
    href: "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faEnvelope} beat />,
    title: "Correo Electrónico",
  },
  propsEmailText: {
    href: "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    target: "_blank",
    text: "info@dataentryperu.com",
    title: "Correo Electrónico",
  },

  // RIGHT side

  propsWhatsapp: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faSquareWhatsapp} />,
    title: "WhatsApp",
  },
  propsX: {
    href: "https://x.com/DATAENTRYPERU",
    target: "_blank",
    text: <FontAwesomeIcon icon={faSquareXTwitter} />,
    title: "X",
  },
  propsFacebook: {
    href: "#",
    target: "_blank",
    text: <FontAwesomeIcon icon={faSquareFacebook} />,
    title: "Facebook",
  },
  propsLinkedin: {
    href: "https://pe.linkedin.com/company/dataentryperu",
    target: "_blank",
    text: <FontAwesomeIcon icon={faLinkedin} />,
    title: "Linkedin",
  },
  propsAriba: {
    href: "https://portal.us.bn.cloud.ariba.com/profile/public?anId=AN11203232276&sourceApplication=SBN",
    target: "_blank",
    title: "Ariba Network",
    src: "https://service.ariba.com/an/p/Ariba/es/badge_180x55.jpg",
    className: "aribaImg",
    alt: "Ariba icon",
  },
};
