import { ContactFooter } from "./ContactFooter";
import { RightsSocialFooter } from "./RightsSocialFooter";

export const Footer = () => {
  return (
    <footer>
      <ContactFooter />
      <RightsSocialFooter />
    </footer>
  );
};
