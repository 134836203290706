import { createContext, useState } from "react";
import { languageTranslation } from "../data/languageTranslation";

export const LanguageContext = createContext(); // Se crea con el mismo nombre del archivo
const initialLanguage = "es";

export const LanguageProvider = ({ children }) => {
  const [value, setValue] = useState(initialLanguage);
  const [texts, setTexts] = useState(languageTranslation[initialLanguage]);
  const handleLanguage = () => {
    const addClass = document.querySelector(".flagImg");
    addClass.classList.add("retraso");

    if (value === "es") {
      setValue("en");
      setTexts(languageTranslation.en);
    } else {
      setValue("es");
      setTexts(languageTranslation.es);
    }
  };
  const data = { value, texts, handleLanguage };

  return (
    <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
  );
};
