export const PropsClientsMain = {
  tituloSeccion: "NUESTROS CLIENTES",
  logo1: {
    imgSrc: "assets/logos/1-usil-logo.png",
    imgAlt: "Logo USIL - Universidad San Ignacio de Loyola",
  },
  logo2: {
    imgSrc: "assets/logos/2-sigma-logo.png",
    imgAlt: "Logo Sigma Data Services",
  },
  logo3: {
    imgSrc: "assets/logos/3-captio-logo.png",
    imgAlt: "Logo Captio",
  },
  logo4: {
    imgSrc: "assets/logos/4-icpna-logo.png",
    imgAlt: "Logo ICPNA - Instituto Cultural Peruano Norteamericano",
  },
  logo5: {
    imgSrc: "assets/logos/5-lap-logo.png",
    imgAlt: "Logo LAP - Lima Airport Partners",
  },
  logo6: {
    imgSrc: "assets/logos/6-interbank-logo.png",
    imgAlt: "Logo Interbank",
  },
  logo7: {
    imgSrc: "assets/logos/7-el-comercio-logo.png",
    imgAlt: "Logo El Comercio",
  },
  logo8: {
    imgSrc: "assets/logos/8-gd-test-prep-logo.png",
    imgAlt: "Logo GD Test Prep",
  },
  logo9: {
    imgSrc: "assets/logos/9-balbi-consultores-logo.png",
    imgAlt: "Logo Balbi Consultores",
  },
  logo10: {
    imgSrc: "assets/logos/10-belinca-logo.png",
    imgAlt: "Logo Belinca",
  },
  logo11: {
    imgSrc: "assets/logos/11-tani-logo.png",
    imgAlt: "Logo TANI - Asociación Taller de los Niños",
  },
};
