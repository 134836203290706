export const Anchor = ({
  closeMenuResponsivo = () => {},
  textHtml = "",
  className = "",
  propsAnchor,
}) => {
  const typeSrc = propsAnchor.src ? (
    <img
      src={propsAnchor.src}
      className={propsAnchor.className}
      alt={propsAnchor.alt}
    />
  ) : textHtml ? (
    <p dangerouslySetInnerHTML={{ __html: textHtml }} />
  ) : (
    propsAnchor.text
  );

  return (
    <a
      href={propsAnchor.href}
      target={propsAnchor.target}
      title={propsAnchor.title}
      className={className}
      onClick={closeMenuResponsivo}
    >
      {typeSrc}
    </a>
  );
};
