import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { ClientsMainCarrousel } from "./ClientsMainCarrousel";
import { PropsClientsMain } from "./types/PropsClientsMain";
import "./clientsMain.css";

export const ClientsMain = () => {
  const { texts } = useContext(LanguageContext);
  PropsClientsMain.tituloSeccion = texts.clientsMainTitle;
  return (
    <section className="contenedor-clientes">
      <h3 className="titulo-principal">{PropsClientsMain.tituloSeccion}</h3>
      <div className="clientes">
        <ClientsMainCarrousel props={PropsClientsMain.logo1} />
        <ClientsMainCarrousel props={PropsClientsMain.logo2} />
        <ClientsMainCarrousel props={PropsClientsMain.logo3} />
        <ClientsMainCarrousel props={PropsClientsMain.logo4} />
        <ClientsMainCarrousel props={PropsClientsMain.logo5} />
        <ClientsMainCarrousel props={PropsClientsMain.logo6} />
        <ClientsMainCarrousel props={PropsClientsMain.logo7} />
        <ClientsMainCarrousel props={PropsClientsMain.logo8} />
        <ClientsMainCarrousel props={PropsClientsMain.logo9} />
        <ClientsMainCarrousel props={PropsClientsMain.logo10} />
        <ClientsMainCarrousel props={PropsClientsMain.logo11} />

        <ClientsMainCarrousel props={PropsClientsMain.logo1} />
        <ClientsMainCarrousel props={PropsClientsMain.logo2} />
        <ClientsMainCarrousel props={PropsClientsMain.logo3} />
        <ClientsMainCarrousel props={PropsClientsMain.logo4} />
        <ClientsMainCarrousel props={PropsClientsMain.logo5} />
        <ClientsMainCarrousel props={PropsClientsMain.logo6} />
        <ClientsMainCarrousel props={PropsClientsMain.logo7} />
        <ClientsMainCarrousel props={PropsClientsMain.logo8} />
        <ClientsMainCarrousel props={PropsClientsMain.logo9} />
        <ClientsMainCarrousel props={PropsClientsMain.logo10} />
        <ClientsMainCarrousel props={PropsClientsMain.logo11} />
      </div>
    </section>
  );
};
