import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { Anchor, ButtonToogle } from "../../common/";
import { PropsAnchors } from "./types/PropsAnchors";
import { propsButtonFlag } from "./types/propsButton";
import "./bannerHeaderItems.css";

export const BannerHeaderItems = () => {
  const { value, texts, handleLanguage } = useContext(LanguageContext);

  PropsAnchors.propsPhoneText.title = texts.bannerHeaderAnchorPhoneTitle;
  PropsAnchors.propsPhoneIcon.title = texts.bannerHeaderAnchorPhoneTitle;

  PropsAnchors.propsEmailIcon.title = texts.bannerHeaderAnchorEmailTitle;
  PropsAnchors.propsEmailText.title = texts.bannerHeaderAnchorEmailTitle;
  PropsAnchors.propsEmailIcon.href = texts.bannerHeaderButtonEmailHref;
  PropsAnchors.propsEmailText.href = texts.bannerHeaderButtonEmailHref;

  propsButtonFlag.title = texts.bannerHeaderButtonFlagTitle;
  propsButtonFlag.title = texts.bannerHeaderButtonFlagTitle;
  propsButtonFlag.src =
    value === "es" ? "assets/Flag_Spanish.png" : "assets/Flag_English.png";
  propsButtonFlag.onClick = handleLanguage;

  return (
    <>
      <div className="banner-header-phone banner-fadein">
        <Anchor propsAnchor={PropsAnchors.propsPhoneIcon} />
        <Anchor propsAnchor={PropsAnchors.propsPhoneText} />
      </div>
      <div className="banner-header-email banner-fadein">
        <Anchor propsAnchor={PropsAnchors.propsEmailIcon} />
        <Anchor propsAnchor={PropsAnchors.propsEmailText} />
      </div>
      <div className="banner-header-flag banner-fadein">
        <ButtonToogle propsButton={propsButtonFlag} />
      </div>
      <div className="banner-header-social banner-fadein">
        <Anchor propsAnchor={PropsAnchors.propsWhatsapp} />
        <Anchor propsAnchor={PropsAnchors.propsX} />
        <Anchor propsAnchor={PropsAnchors.propsFacebook} />
        <Anchor propsAnchor={PropsAnchors.propsLinkedin} />
        <Anchor propsAnchor={PropsAnchors.propsAriba} />
      </div>
    </>
  );
};
