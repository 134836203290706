import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { PropsServiceMain } from "./types/PropsServiceMain";
import { ServiceCardMain } from "./ServiceCardMain";

export const ServicesMain = () => {
  const { texts } = useContext(LanguageContext);
  PropsServiceMain.digitacion.imgAlt = texts.servicesMainDigitacionImgAlt;
  PropsServiceMain.digitacion.titulo = texts.servicesMainDigitacionTitle;
  PropsServiceMain.digitacion.detalle = texts.servicesMainDigitacionDetail;

  PropsServiceMain.escaneos.imgAlt = texts.servicesMainDigitalizacionImgAlt;
  PropsServiceMain.escaneos.titulo = texts.servicesMainDigitalizacionTitle;
  PropsServiceMain.escaneos.detalle = texts.servicesMainDigitalizacionDetail;

  PropsServiceMain.transcripciones.imgAlt =
    texts.servicesMainTranscriptionImgAlt;
  PropsServiceMain.transcripciones.titulo =
    texts.servicesMainTranscriptionTitle;
  PropsServiceMain.transcripciones.detalle =
    texts.servicesMainTranscriptionDetail;

  PropsServiceMain.desarrolloWeb.imgAlt = texts.servicesMainDesarrolloWebImgAlt;
  PropsServiceMain.desarrolloWeb.titulo = texts.servicesMainDesarrolloWebTitle;
  PropsServiceMain.desarrolloWeb.detalle =
    texts.servicesMainDesarrolloWebDetail;

  PropsServiceMain.desarrolloSoftware.imgAlt =
    texts.servicesMainDesarrolloSoftwareImgAlt;
  PropsServiceMain.desarrolloSoftware.titulo =
    texts.servicesMainDesarrolloSoftwareTitle;
  PropsServiceMain.desarrolloSoftware.detalle =
    texts.servicesMainDesarrolloSoftwareDetail;

  PropsServiceMain.ingresoDatosWeb.imgAlt =
    texts.servicesMainIngresoDatosWebImgAlt;
  PropsServiceMain.ingresoDatosWeb.titulo =
    texts.servicesMainIngresoDatosWebTitle;
  PropsServiceMain.ingresoDatosWeb.detalle =
    texts.servicesMainIngresoDatosWebDetail;

  const digitacion = PropsServiceMain.digitacion;
  const escaneos = PropsServiceMain.escaneos;
  const transcripciones = PropsServiceMain.transcripciones;
  const desarrolloWeb = PropsServiceMain.desarrolloWeb;
  const desarrolloSoftware = PropsServiceMain.desarrolloSoftware;
  const ingresoDatosWeb = PropsServiceMain.ingresoDatosWeb;

  return (
    <section className=" servicios" id="servicios">
      <div>
        <h3 className="titulo-principal ">{texts.servicesMainTitle}</h3>
      </div>
      <section className="lista-servicios">
        <div className="cards">
          <ServiceCardMain props={digitacion} />
          <ServiceCardMain props={escaneos} />
          <ServiceCardMain props={transcripciones} />
          <ServiceCardMain props={desarrolloWeb} />
          <ServiceCardMain props={desarrolloSoftware} />
          <ServiceCardMain props={ingresoDatosWeb} />
        </div>
      </section>
    </section>
  );
};
