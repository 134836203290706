import { ItemsDiagramMain } from "./ItemsDiagramMain";

export const DiagramMain = () => {
  return (
    <section>
      <video
        src="assets/DataEntryPeruVideo.mp4"
        type="video/mp4"
        autoPlay
        loop
        muted
      ></video>
      <ItemsDiagramMain />
    </section>
  );
};
