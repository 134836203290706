import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";

import { Anchor } from "../../common/Anchor";
import { propsLogo } from "./types/propsAnchors";
import "./logoHeader.css";

export const LogoHeader = () => {
  const { value, handleLanguage } = useContext(LanguageContext);
  propsLogo.src =
    value === "es"
      ? "assets/LogoDataEntryPeru_es.png"
      : "assets/LogoDataEntryPeru_en.png";
  propsLogo.onClick = handleLanguage;

  return (
    <div>
      <Anchor propsAnchor={propsLogo} />
    </div>
  );
};
