import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { Anchor } from "../common";
import { PropsAnchors } from "./types/PropsAnchors";
import "./contactFooter.css";

export const ContactFooter = () => {
  const { texts } = useContext(LanguageContext);
  PropsAnchors.propsAddressIcon.title = texts.footerMainAddressIconTitle;
  PropsAnchors.propsAddressText.text = texts.footerMainAddressTextText;
  PropsAnchors.propsAddressText.title = texts.footerMainAddressTextTitle;

  PropsAnchors.propsPhoneIcon.href = texts.footerMainPhoneIconHref;
  PropsAnchors.propsPhoneIcon.title = texts.footerMainPhoneIconTitle;
  PropsAnchors.propsPhoneText.href = texts.footerMainPhoneTextHref;
  PropsAnchors.propsPhoneText.title = texts.footerMainPhoneTextTitle;

  PropsAnchors.propsEmailIcon.href = texts.footerMainEmailIconHref;
  PropsAnchors.propsEmailIcon.title = texts.footerMainEmailIconTitle;
  PropsAnchors.propsEmailText.href = texts.footerMainEmailTextHref;
  PropsAnchors.propsEmailText.title = texts.footerMainEmailTextTitle;

  return (
    <>
      <section className="footer-contacto">
        <div>
          <Anchor
            className="icon-banner1"
            propsAnchor={PropsAnchors.propsAddressIcon}
          />
          <Anchor
            className="text-banner1"
            propsAnchor={PropsAnchors.propsAddressText}
          />
        </div>
        <div>
          <Anchor
            className="icon-banner1"
            propsAnchor={PropsAnchors.propsPhoneIcon}
          />
          <Anchor
            className="text-banner1"
            propsAnchor={PropsAnchors.propsPhoneText}
          />
        </div>
        <div>
          <Anchor
            className="icon-banner1"
            propsAnchor={PropsAnchors.propsEmailIcon}
          />
          <Anchor
            className="text-banner1"
            propsAnchor={PropsAnchors.propsEmailText}
          />
        </div>
      </section>
    </>
  );
};
