import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faXTwitter,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const year = new Date().getFullYear();

// RIGHT side

export const PropsAnchors = {
  // BANNER 1
  propsAddressIcon: {
    href: "",
    target: "_blank",
    text: <FontAwesomeIcon icon={faLocationDot} />,
    title: "Dirección",
  },
  propsAddressText: {
    href: "",
    target: "_blank",
    text: "Jesús María, Lima - PERÚ",
    title: "Dirección",
  },
  propsPhoneIcon: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faWhatsapp} beat />,
    title: "Móvil",
  },
  propsPhoneText: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: "(+51) 999 064 169",
    title: "Móvil",
  },
  propsEmailIcon: {
    href: "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faEnvelope} beat />,
    title: "Correo Electrónico",
  },
  propsEmailText: {
    href: "mailto:info@dataentryperu.com?subject=Correo enviado desde DATAENTRYPERU.COM",
    target: "_blank",
    text: "info@dataentryperu.com",
    title: "Correo Electrónico",
  },

  // BANNER 2
  propsCopyrightText: {
    text: `Copyright © ${year} ENTRY PERU. Elaborado por: Data Entry Perú | `,
  },
  propsEnlaceDep: {
    text: "<strong>www.dataentryperu.com</strong>",
  },
  propsWhatsapp: {
    href: "https://wa.me/51999064169?text=Hola, quisiera más información sobre los servicios que brinda DATAENTRYPERU.COM",
    target: "_blank",
    text: <FontAwesomeIcon icon={faWhatsapp} />,
    title: "WhatsApp",
  },
  propsX: {
    href: "https://x.com/DATAENTRYPERU",
    target: "_blank",
    text: <FontAwesomeIcon icon={faXTwitter} />,
    title: "X",
  },
  propsFacebook: {
    href: "#",
    target: "_blank",
    text: <FontAwesomeIcon icon={faFacebookF} />,
    title: "Facebook",
  },
  propsLinkedin: {
    href: "https://pe.linkedin.com/company/dataentryperu",
    target: "_blank",
    text: <FontAwesomeIcon icon={faLinkedinIn} />,
    title: "Linkedin",
  },
  propsAriba: {
    href: "https://portal.us.bn.cloud.ariba.com/profile/public?anId=AN11203232276&sourceApplication=SBN",
    target: "_blank",
    title: "Ariba Network",
    src: "https://service.ariba.com/an/p/Ariba/es/badge_180x55.jpg",
    className: "aribaImg",
    alt: "Ariba icon",
  },
};
