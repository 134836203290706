import "./hamburguerHeader.css";

export const HamburguerHeader = ({ openMenuResponsivo, isOpen }) => {
  return (
    <div
      className={`hamburguer ${isOpen && "open"}`}
      onClick={openMenuResponsivo}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
