export const PropsServiceMain = {
  digitacion: {
    classNameImg: "imagen-servicio",
    imagen: "",
    imgSrc: "assets/Servicio_Digitacion.jpg",
    imgAlt: "Servicio de Digitación",
    classNameTitulo: "titulo-servicio",
    titulo: "DIGITACIÓN MASIVA DE DATOS",
    classNameDetalle: "detalle-servicio",
    detalle: `Nos especializamos en el ingreso masivo de datos de encuestas, cupones, fichas, formularios, registros, etc. Toda la data ingresada es cuidadosamente revisada, validada y corregida para lograr un alto estándar de calidad.`,
  },
  escaneos: {
    classNameImg: "imagen-servicio",
    imgSrc: "assets/Servicio_Digitalizacion.jpg",
    imgAlt: "Servicio de Digitalizacion",
    classNameTitulo: "titulo-servicio",
    titulo: "DIGITALIZACIONES (ESCANEOS)",
    classNameDetalle: "detalle-servicio",
    detalle:
      "Transformamos documentos físicos en archivos digitales para un mejor acceso y conservación de tu información. Escaneamos y clasificamos todo tipo de documentos personales o empresariales, fotos y diapositivas.",
  },
  transcripciones: {
    classNameImg: "imagen-servicio",
    imgSrc: "assets/Servicio_Transcripcion.jpg",
    imgAlt: "Servicio de Transcripción",
    classNameTitulo: "titulo-servicio",
    titulo: "TRANSCRIPCIONES DE AUDIO A TEXTO",
    classNameDetalle: "detalle-servicio",
    detalle:
      "Transcribimos manualmente y con total confidencialidad, audios o videos a archivos de texto editables con marcación de tiempos. Transcribe reuniones, presentaciones, clases, focus group, conferencias, entrevistas, etc.",
  },
  desarrolloWeb: {
    classNameImg: "imagen-servicio",
    imgSrc: "assets/Servicio_Desarrollo_Web.jpg",
    imgAlt: "Servicio de Desarrollo Web",
    classNameTitulo: "titulo-servicio",
    titulo: "DESARROLLO DE PÁGINAS WEB",
    classNameDetalle: "detalle-servicio",
    detalle:
      "Desarrollamos Páginas Web personales o empresariales 100% adaptables a dispositivos móviles. Implementamos y configuramos tiendas de comercio electrónico. Tenemos paquetes que incluyen el Hosting y Dominio.",
  },
  desarrolloSoftware: {
    classNameImg: "imagen-servicio",
    imgSrc: "assets/Servicio_Desarrollo_Software.jpg",
    imgAlt: "Servicio de Desarrollo de Software",
    classNameTitulo: "titulo-servicio",
    titulo: "DESARROLLO DE SOFTWARE",
    classNameDetalle: "detalle-servicio",
    detalle:
      "Desarrollamos sistemas y programas computacionales, orientado principalmente a personas particulares y a la micro/pequeña empresa que necesite simplificar, automatizar y optimizar sus procesos y tareas diarias.",
  },
  ingresoDatosWeb: {
    classNameImg: "imagen-servicio",
    imgSrc: "assets/Servicio_Ingreso_Datos_Web.jpg",
    imgAlt: "Servicio de Ingreso de Datos en Internet",
    classNameTitulo: "titulo-servicio",
    titulo: "INGRESO DE DATOS EN INTERNET",
    classNameDetalle: "detalle-servicio",
    detalle:
      "Administramos y actualizamos información de tus Redes Sociales, Blogs, Páginas Web, Tiendas Online, Gestores de Contenido (CMS - Content Management System), Sistemas de Gestión de Aprendizaje (LMS - Learning Management System), etc.",
  },
};
