import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { PropsItemsDiagram } from "./types/PropsItemsDiagram";
import { ItemDiagramMain } from "./ItemDiagramMain";
import "./diagramMain.css";

export const ItemsDiagramMain = () => {
  const { texts } = useContext(LanguageContext);
  PropsItemsDiagram.digitacion.text = texts.diagramMainItemDigitacion;
  PropsItemsDiagram.digitalizacion.text = texts.diagramMainItemDigitalizacion;
  PropsItemsDiagram.transcripcion.text = texts.diagramMainItemTranscripcion;
  PropsItemsDiagram.desarrolloWeb.text = texts.diagramMainItemDesarrolloWeb;
  PropsItemsDiagram.desarrolloSoftware.text =
    texts.diagramMainItemDesarrolloSoftware;
  PropsItemsDiagram.ingresoDatosWeb.text = texts.diagramMainItemIngresoDatosWeb;

  const digitacion = PropsItemsDiagram.digitacion;
  const digitalizacion = PropsItemsDiagram.digitalizacion;
  const transcripcion = PropsItemsDiagram.transcripcion;
  const desarrolloWeb = PropsItemsDiagram.desarrolloWeb;
  const desarrolloSoftware = PropsItemsDiagram.desarrolloSoftware;
  const ingresoDatosWeb = PropsItemsDiagram.ingresoDatosWeb;

  return (
    <div className="diagrama">
      <div className="diagrama-items diagrama-fadein">
        <div className="poste"></div>
        <ItemDiagramMain props={digitacion} />
        <ItemDiagramMain props={transcripcion} />
        <ItemDiagramMain props={digitalizacion} />
        <ItemDiagramMain props={desarrolloWeb} />
        <ItemDiagramMain props={desarrolloSoftware} />
        <ItemDiagramMain props={ingresoDatosWeb} />
      </div>
    </div>
  );
};
