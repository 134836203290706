import { createRef, useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { PropsContactMain } from "./types/PropsContactMain";
import ReCAPTCHA from "react-google-recaptcha";
// Librería para cuadro de mensaje enviado (https://sweetalert2.github.io/)
import Swal from "sweetalert2";
import "./contactMain.css";

export const ContactMain = () => {
  const { value, texts } = useContext(LanguageContext);
  PropsContactMain.propsContactMainTitle = texts.contactMainTitle;
  PropsContactMain.propsNombre.placeHolder = texts.contactMainName;
  PropsContactMain.propsCorreo.placeHolder = texts.contactMainMail;
  PropsContactMain.propsMensaje.placeHolder = texts.contactMainMessage;
  PropsContactMain.propsEnviar.value = texts.contactMainSend;
  PropsContactMain.propsAlertOk.title = texts.contactMainAlertOkTitle;
  PropsContactMain.propsAlertOk.text = texts.contactMainAlertOkText;
  PropsContactMain.propsAlertFail.title = texts.contactMainAlertFailTitle;
  PropsContactMain.propsAlertFail.text = texts.contactMainAlertFailText;

  const recaptchaRef = createRef();
  function onChange(e) {
    console.log("Captcha value:", e);
  }

  const onSubmit = async (event) => {
    // Código de web3forms.com
    event.preventDefault();
    recaptchaRef.current.execute();
    const formData = new FormData(event.target);
    formData.append("access_key", "508d4755-d70b-4141-8bbf-cf9000b77eef");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      // Código de https://sweetalert2.github.io/
      Swal.fire({
        title: PropsContactMain.propsAlertOk.title,
        text: PropsContactMain.propsAlertOk.text,
        icon: "success",
      });
      // ----------------------------------------
      event.target.reset();
    } else {
      Swal.fire({
        title: PropsContactMain.propsAlertFail.title,
        text: PropsContactMain.propsAlertFail.text,
        icon: "error",
      });
    }
  };
  // Fin del código de la librería web3forms.com

  return (
    <section className="contactanos" id="contacto">
      <h3 className="titulo-principal">
        {PropsContactMain.propsContactMainTitle}
      </h3>

      <form
        className="formulario"
        action=""
        id="formulario"
        onSubmit={onSubmit}
      >
        <input
          type="hidden"
          name="access_key"
          value="508d4755-d70b-4141-8bbf-cf9000b77eef"
        ></input>
        <input
          type="hidden"
          name="subject"
          value="Mensaje de DATAENTRYPERU.COM"
        />
        <input
          type="hidden"
          name="from_name"
          value="www.dataentryperu.com"
        ></input>
        <input
          type={PropsContactMain.propsNombre.type}
          placeholder={PropsContactMain.propsNombre.placeHolder}
          name={PropsContactMain.propsNombre.name}
          id={PropsContactMain.propsNombre.id}
          value={PropsContactMain.propsNombre.value}
          required
        />
        <input
          type={PropsContactMain.propsCorreo.type}
          placeholder={PropsContactMain.propsCorreo.placeHolder}
          name={PropsContactMain.propsCorreo.name}
          id={PropsContactMain.propsCorreo.id}
          value={PropsContactMain.propsCorreo.value}
          required
        />
        <textarea
          type={PropsContactMain.propsMensaje.type}
          placeholder={PropsContactMain.propsMensaje.placeHolder}
          name={PropsContactMain.propsMensaje.name}
          id={PropsContactMain.propsMensaje.id}
          value={PropsContactMain.propsMensaje.value}
          required
        ></textarea>
        <input
          type="checkbox"
          name="botcheck"
          className="hidden"
          style={{ display: "none" }}
        ></input>
        <input
          type="submit"
          name="submit"
          className="boton"
          value={PropsContactMain.propsEnviar.value}
          id="enviar"
        />
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LdxLxYqAAAAAKY9Zs84CEy3jEcgStDNf8AyvD1Q"
        onChange={onChange}
        size="invisible"
        hl={value}
      />
    </section>
  );
};
