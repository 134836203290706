import { BannerHeader } from "./bannerHeader/BannerHeader";
import { NavbarHeader } from "./navbarHeader/NavbarHeader";

export const Header = () => {
  return (
    <>
      <BannerHeader />
      <header>
        <NavbarHeader />
      </header>
    </>
  );
};
