import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./chevronUp.css";

export const ChevronUp = () => {
  useEffect(() => {
    window.addEventListener("scroll", function () {
      const chevron = document.querySelector(".chevron");
      chevron.classList.toggle("open-chevron", window.scrollY > 20);
    });
  }, []);

  const onClickHandler = () => {
    window.scroll({
      top: 0,
      left: -75,
      behavior: "smooth",
    });
  };

  return (
    <span className="chevron" onClick={onClickHandler}>
      <FontAwesomeIcon icon={faChevronUp} />
    </span>
  );
};
