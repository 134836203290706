// RIGHT side

export const propsInicio = {
  href: "#",
  target: "",
  text: "Inicio",
  title: "Inicio",
};
export const propsNosotros = {
  href: "#nosotros",
  target: "",
  text: "Nosotros",
  title: "Nosotros",
};
export const propsServicios = {
  href: "#servicios",
  target: "",
  text: "Servicios",
  title: "Servicios",
};
export const propsContacto = {
  href: "#contacto",
  target: "",
  text: "Contacto",
  title: "Contacto",
};
export const propsLogo = {
  href: "#",
  title: "Data Entry Peru",
  src: "assets/LogoDataEntryPeru_es.png",
  className: "logo-header",
  alt: "Logo de Data Entry Perú",
};
