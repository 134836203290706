import { useContext } from "react";
import { PropsCompanyMain } from "./types/PropsCompanyMain";
import { LanguageContext } from "../../../context/LanguageContext";
import "./companyMain.css";

export const CompanyMain = () => {
  const { texts } = useContext(LanguageContext);
  PropsCompanyMain.textoPrincipal = texts.companyMainPrimaryText;
  PropsCompanyMain.textoSecundario = texts.companyMainSecondaryText;
  PropsCompanyMain.titulo = texts.companyMainTitle;
  return (
    <section className="nosotros" id="nosotros">
      <h3 className="titulo-principal">{PropsCompanyMain.titulo}</h3>
      <div
        className="texto-principal"
        dangerouslySetInnerHTML={{ __html: PropsCompanyMain.textoPrincipal }}
      />
      <div className="texto-secundario">{PropsCompanyMain.textoSecundario}</div>
    </section>
  );
};
