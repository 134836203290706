// import { BrowserRouter } from "react-router-dom";
import { Header } from "./components/header/Header";
import { Main } from "./components/main/Main";
import { LanguageProvider } from "./context/LanguageContext.jsx";
import { Footer } from "./components/footer/Footer.jsx";
import { ChevronUp } from "./components/common/ChevronUp.jsx";

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <LanguageProvider>
        <ChevronUp />
        <Header />
        <Main />
        <Footer />
      </LanguageProvider>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
