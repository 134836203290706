import "./servicesMain.css";

export const ServiceCardMain = ({ props }) => {
  return (
    <div>
      <div className={props.classNameImg}>
        <img src={props.imgSrc} alt={props.imgAlt} />
      </div>
      <div className={props.classNameTitulo}>
        <h3>{props.titulo}</h3>
      </div>
      <div
        className={props.classNameDetalle}
        dangerouslySetInnerHTML={{ __html: props.detalle }}
      />
    </div>
  );
};
