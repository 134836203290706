export const ButtonToogle = ({ propsButton }) => {
  const typeSrc = propsButton.src ? (
    <img
      src={propsButton.src}
      alt={propsButton.alt}
      title={propsButton.title}
      className={propsButton.className}
    />
  ) : (
    propsButton.scr
  );

  return (
    <button type={propsButton.type} onClick={propsButton.onClick}>
      {typeSrc}
    </button>
  );
};
