import { useContext } from "react";
import { propsPhraseMain } from "./types/propsPhraseMain";
import { LanguageContext } from "../../../context/LanguageContext";
import "./phraseMain.css";

export const PhraseMain = () => {
  const { texts } = useContext(LanguageContext);
  propsPhraseMain.frase = texts.phraseMainText;
  return (
    <section className="frase-main">
      <div className="frase">{propsPhraseMain.frase}</div>
    </section>
  );
};
