import { LogoHeader } from "./LogoHeader";
import { MenuHeader } from "./MenuHeader";
import "./navbarHeader.css";

export const NavbarHeader = () => {
  return (
    <section className="navbar-header">
      <LogoHeader />
      <MenuHeader />
    </section>
  );
};
