import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { Anchor } from "../../common/Anchor";
import { HamburguerHeader } from "./HamburguerHeader";
import {
  propsInicio,
  propsNosotros,
  propsServicios,
  propsContacto,
} from "./types/propsAnchors";
import "./menuHeader.css";

export const MenuHeader = () => {
  const { texts } = useContext(LanguageContext);
  propsInicio.title = texts.navbarHeaderAnchorInicioTitle;
  propsNosotros.title = texts.navbarHeaderAnchorNosotrosTitle;
  propsServicios.title = texts.navbarHeaderAnchorServiciosTitle;
  propsContacto.title = texts.navbarHeaderAnchorContactoTitle;

  propsInicio.text = texts.navbarHeaderAnchorInicioText;
  propsNosotros.text = texts.navbarHeaderAnchorNosotrosText;
  propsServicios.text = texts.navbarHeaderAnchorServiciosText;
  propsContacto.text = texts.navbarHeaderAnchorContactoText;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var header = document.querySelector(".navbar-header");
      header.classList.toggle("header-sticky", window.scrollY > 75);
    });
  }, []);

  // Función que va al hijo para que el hijo cambie el estado isOpen mediante un evento y pueda usar esa variable de estado tanto en el padre como en el hijo
  const openMenuResponsivo = () => {
    setIsOpen(!isOpen);
  };

  const closeMenuResponsivo = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <nav className={`menu-header ${isOpen && "open"}`}>
        <Anchor
          propsAnchor={propsInicio}
          closeMenuResponsivo={closeMenuResponsivo}
        />
        <Anchor
          propsAnchor={propsNosotros}
          closeMenuResponsivo={closeMenuResponsivo}
        />
        <Anchor
          propsAnchor={propsServicios}
          closeMenuResponsivo={closeMenuResponsivo}
        />
        <Anchor
          propsAnchor={propsContacto}
          closeMenuResponsivo={closeMenuResponsivo}
        />
      </nav>
      <HamburguerHeader
        openMenuResponsivo={openMenuResponsivo}
        isOpen={isOpen}
      />
    </div>
  );
};
