import { BannerHeaderItems } from "./BannerHeaderItems";
import "./bannerHeader.css";

export const BannerHeader = () => {
  return (
    <section className="banner-header">
      <BannerHeaderItems />
    </section>
  );
};
