import { DiagramMain } from "./diagramMain/DiagramMain";
import { CompanyMain } from "./companyMain/CompanyMain";
import { PhraseMain } from "./phraseMain/PhraseMain";
import { ServicesMain } from "./servicesMain/ServicesMain";
import { ClientsMain } from "./clientsMain/ClientsMain";
import { ContactMain } from "./contacMain/ContactMain";

export const Main = () => {
  return (
    <main>
      <DiagramMain />
      <CompanyMain />
      <PhraseMain />
      <ServicesMain />
      <ClientsMain />
      <ContactMain />
    </main>
  );
};
