export const PropsContactMain = {
  propsContactMainTitle: "CONTÁCTANOS",
  propsNombre: {
    type: "text",
    placeHolder: "Nombre:",
    name: "name",
    id: "nombre",
  },
  propsCorreo: {
    type: "email",
    placeHolder: "Correo:",
    name: "email",
    id: "correo",
  },
  propsMensaje: {
    placeHolder: "Mensaje:",
    name: "mensaje",
    id: "message",
  },
  propsEnviar: {
    type: "submit",
    name: "enviar",
    id: "enviar",
    value: "Enviar",
  },
  propsAlertOk: {
    title: "¡Envío Exitoso!",
    text: "Gracias por comunicarte con nosotros. Te responderemos lo antes posible.",
  },
  propsAlertFail: {
    title: "¡Tu mensaje no pudo ser enviado!",
    text: "Por favor, inténtalo de nuevo más tarde.",
  },
};
